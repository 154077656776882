<template>
  <div>
    <!--begin::Forgot Password-->
    <div v-if="show" class="login-form login-forgot">
      <!--begin::Title-->
      <div  v-if="status == 'loading'" class="pb-5 pt-lg-0 pt-5">
        <ListLoader></ListLoader>
      </div>

      <div v-else class="pb-5 pt-lg-0 pt-5">
          <h3 class="font-weight-bolder text-dark font-size-h2 font-size-h1-lg">
            <span v-if="listStatus(status).logo" class="svg-icon svg-icon-4x" :class="`svg-icon-${listStatus(status).action}`">
              <!--begin::Svg Icon-->
              <inline-svg :src="`media/svg/icons/${listStatus(status).logo}`" :keepDuringLoading="false" />
              <!--end::Svg Icon-->
            </span>
            {{ listStatus(status).judul }}
          </h3>
          <p class="text-muted font-weight-bold font-size-h4 pb-5">
            {{ listStatus(status).desc }}
          </p>
          <b-button v-if="listStatus(status).button.includes('dashboard')" @click="onRedirect" class="m-1 font-weight-bolder font-size-h6" variant="primary">{{ NameRedirect }}</b-button>
          <b-button v-if="listStatus(status).button.includes('send')" @click="onResend" ref="kt_email_verify_send" class="m-1 font-weight-bolder font-size-h6" variant="primary">{{ listStatus(status).buttonName }}</b-button>
          <b-button v-if="listStatus(status).button.includes('logout')" @click="onLogout" class="m-1 font-weight-bolder font-size-h6" variant="light">Logout</b-button>
      </div>

    </div>
    <!--end::Forgot Password-->
  </div>
</template>

<script>
import { mapState } from "vuex";
import { ListLoader } from 'vue-content-loader'
import { LOGOUT } from "@/core/services/store/auth.module";
import ErrorService from "@/core/services/error.service";
import ProfileService from "@/core/services/api/profile.service";

export default {
  name: "auth-email-verified",
  components: {
    ListLoader
  },
  data() {
    return {
      show: true,
      status: 'loading'
    }
  },
  methods: {
    listStatus(status) {
      if (status == 'notify') return {
        action: null,
        logo: null,
        judul: 'Email Verification',
        desc: `This action requires email verification. Please check your inbox and follow intructions. Email Sent ${this.activeUserInfo.email}`,
        buttonName: 'Send',
        button: ['send', 'logout']
      }
      else if (status == 'send') return {
        action: 'primary',
        logo: 'Communication/Readed-mail.svg',
        judul: 'Check your email',
        desc: `We have sent a link to verify your email. Email Sent ${this.activeUserInfo.email}`,
        buttonName: 'Resend',
        button: ['send', 'logout']
      }
      else if (status == 'verified') return {
        action: 'primary',
        logo: 'Navigation/Double-check.svg',
        judul: 'Account Activated',
        desc: `Hallo ${this.activeUserInfo.name}. Thank you, your email has been verified. Your account is now active.`,
        buttonName: null,
        button: ['dashboard', 'logout']
      }
      else return {
        action: null,
        logo: null,
        judul: null,
        desc: null,
        button: []
      }
    },
    getEmailStatus() {
      ProfileService.statusEmail()
        .then((res) => {
            if (!res.data.status) {
                ErrorService.message(res.data)
                return;
            }
            this.status = res.data.data
        })
        .catch((err) => ErrorService.status(err))
    },  
    onRedirect() {
      if (this.$route.query.rget && this.$route.query.rget != '/auth/email-verified') {
        return this.$router.push({ path: this.$route.query.rget }).catch(()=>{}) 
      }
      this.$router.push({ name: "dashboard" }).catch(()=>{})
    },
    removeSpinner(button) { 
      button.classList.remove(
        "spinner",
        "spinner-light",
        "spinner-right"
      );
    },
    onResend() {
      const getTime = window.localStorage.getItem("time_resend_mlvry")
      // set spinner to submit button
      const submitButton = this.$refs["kt_email_verify_send"];
      submitButton.classList.add("spinner", "spinner-light", "spinner-right");

      if ((['notify', 'send']).includes(this.status) && getTime < (new Date().getTime())) { 
        // dummy delay
        // setTimeout(() => {
          ProfileService.resendEmailVerify({param: this.$route.query.rget})
          .then((res) => {
            this.removeSpinner(submitButton)
            ErrorService.message(res.data)
            window.localStorage.setItem("time_resend_mlvry", (new Date().getTime()) + 600000);
            this.show = false
            this.status = 'send'
            this.$nextTick(() => {
              this.show = true
            })
          })
          .catch((err) => {
            this.removeSpinner(submitButton)
            ErrorService.status(err)
          })
        // }, 2000);
        return
      }

      // dummy delay
      // setTimeout(() => {
        ErrorService.message({
          action: "Peringatan!!",
          message: "Tidak dapat mengirim email, silahkan ulangi beberapa saat lagi"
        });
        
        this.removeSpinner(submitButton)
      // }, 2000);
    },
    onLogout() {
      this.$store
        .dispatch(LOGOUT)
        .then(() => this.$router.push({ name: "auth-login" }))
        .catch(()=>{})
    },
  },
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    activeUserInfo() {
      return this.$store.getters.currentUser
    },
    NameRedirect() {
      if (this.$route.query.rget && this.$route.query.rget != '/auth/email-verified') {
        return 'Lanjutkan' 
      }
      return 'Dashborad'
    },
  },
  mounted() {
    this.getEmailStatus()
  },
};
</script>
